import {useEffect} from 'react';
import {
    useForm,
    FieldValues,
    Controller,
    useWatch,
} from 'react-hook-form';
import {useApolloClient, useMutation} from '@apollo/client';
import {navigate} from 'gatsby';
import {
    Button,
    Input,
    Modal,
} from '../../components/core';
import Form from '../../components/core/form/form';
import SelectBox from '../../components/core/form/input/selectBox';
import validateEmail from '../../helpers/validators/email';
import * as styles from './addUser.module.css';
import {
    teamLevelsOrder,
    getNewAccessibleLanguages,
    getHiddenLanguages,
} from '../../helpers/pages/user';
import Checkbox from '../../components/core/form/input/checkbox';
import CheckboxGroup from '../../components/core/form/input/checkboxGroup';
import {Team} from '../../interfaces/users';
import Scopes from '../../components/partials/scopes/scopes';
import applications, {applicationDefaultScopes} from '../../configs/applications';
import {AddUserDocument, CheckUserExistsDocument} from '../../graphql-types';
import toastify from '../../helpers/toast/toastify';

const levels = {
    global: 1,
    region: 2,
    bu: 3,
};

interface IAddUserModalProps {
    setModal: (value: string | false) => void,
    teamsData: any,
    isBu: boolean,
    languages: {
        id: string,
        name: string,
    }[],
}

const AddUserModal = ({
    setModal,
    teamsData,
    isBu,
}: IAddUserModalProps) => {
    const [addUser, {loading}] = useMutation(AddUserDocument);
    const apolloClient = useApolloClient();
    const teamOptionsData = isBu ? teamsData : teamLevelsOrder(teamsData);
    const teamOptions = teamOptionsData.map((item: Team) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        level: levels[item.level],
        type: item.level,
    }));
    const {
        setValue,
        control,
        handleSubmit,
        formState: {isValid},
    } = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: {
            name: '',
            email: '',
            teamId: '',
            languages: [],
            isTeamOwner: false,
            accessibleLanguages: [],
            permissions: applicationDefaultScopes,
        },
    });
    const [selectedTeam, selectedLanguages] = useWatch({
        control,
        name: ['teamId', 'accessibleLanguages'],
    });

    const onSubmit = (formData: FieldValues) => {
        const newData = {
            name: formData.name,
            email: formData.email,
            teamId: formData.teamId,
            isTeamOwner: formData.isTeamOwner,
            hiddenLanguageIds: getHiddenLanguages(formData.accessibleLanguages),
            permissions: formData.permissions,
        };

        addUser({
            variables: {
                input: newData,
            },
            onCompleted(data) {
                if (data) {
                    navigate('/users', {
                        state: {
                            toast: {
                                type: 'success',
                                text: 'User created',
                            },
                        },
                    });
                    setModal(false);

                    return;
                }

                toastify({type: 'error', text: 'There was an error on creation'});
            },
            onError(err) {
                toastify({type: 'error', text: `There was an error on creation: ${err.message}`});
            },
            refetchQueries: ['getUsers'],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    useEffect(() => {
        if (!selectedTeam) {
            return;
        }

        setValue('accessibleLanguages', getNewAccessibleLanguages(teamsData, selectedTeam, selectedLanguages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTeam, teamsData]);

    return (
        <Modal
            title="Create new user"
            closeModal={() => setModal(false)}
            buttons={(
                <Button
                    text="Create a new user"
                    disabled={!isValid || loading}
                    size="medium"
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                />
            )}
        >
            <Form>
                <Form.Row>
                    <Form.Column>
                        <Input
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Name is required',
                                },
                            }}
                            title="Name"
                            name="name"
                            placeholder="write the whole name"
                        />
                    </Form.Column>
                    <Form.Column>
                        <Input
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Email is required',
                                },
                                validate: async value => {
                                    const patternValidation = validateEmail(value);

                                    if (typeof patternValidation === 'string') {
                                        return patternValidation;
                                    }

                                    const {data: {userEmailExists}} = await apolloClient.query({
                                        query: CheckUserExistsDocument,
                                        variables: {email: value},
                                    });

                                    return userEmailExists ? 'There is already registered user with this email' : true;
                                },
                            }}
                            title="Email"
                            name="email"
                            type="email"
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column>
                        <SelectBox
                            optionsValues={teamOptions}
                            defaultValue={isBu ? teamOptions : []}
                            control={control}
                            required={true}
                            title="Team"
                            placeholder="choose the team"
                            controllerName="teamId"
                            isDisabled={!!isBu}
                        />
                    </Form.Column>
                    <Form.Column>
                        <div className={styles.ownerCheckbox}>
                            <Controller
                                control={control}
                                name="isTeamOwner"
                                render={({
                                    field: {
                                        onChange,
                                        value,
                                    },
                                }) => (
                                    <Checkbox
                                        checked={value}
                                        label="Owner"
                                        name="isTeamOwner"
                                        handleChange={() => {
                                            onChange(value !== true);
                                        }}
                                    />
                                )}
                            />
                        </div>
                    </Form.Column>
                </Form.Row>
                <div className={styles.scopesLabel}>
                    <span>Permissions</span>
                </div>
                <Form.Row>
                    <Form.Column>
                        {Object
                            .keys(applications)
                            .filter(appKey => applications[appKey].type === 'item')
                            .map(app => {
                                const {name, id} = applications[app];

                                return (
                                    <Scopes
                                        key={`ApplicationScopes_${id}`}
                                        name={name}
                                        group="permissions"
                                        id={id || 'generic'}
                                        control={control}
                                    />
                                );
                            })
                        }
                        <Scopes
                            name="Analytics"
                            group="permissions"
                            id="analytics"
                            standaloneScopes={[
                                'we',
                                'reemea',
                                'apac',
                            ]}
                            control={control}
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column>
                        <CheckboxGroup
                            title="Accessible languages"
                            group="accessibleLanguages"
                            control={control}
                        />
                    </Form.Column>
                </Form.Row>
            </Form>
        </Modal>
    );
};

export default AddUserModal;
