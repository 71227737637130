import {useEffect} from 'react';
import {
    useForm,
    FieldValues,
    Controller,
    useWatch,
} from 'react-hook-form';
import {useMutation} from '@apollo/client';
import {navigate} from 'gatsby';
import {
    Button,
    Input,
    Modal,
} from '../../components/core';
import Form from '../../components/core/form/form';
import Scopes from '../../components/partials/scopes/scopes';
import Checkbox from '../../components/core/form/input/checkbox';
import SelectBox from '../../components/core/form/input/selectBox';
import CheckboxGroup from '../../components/core/form/input/checkboxGroup';
import {
    teamLevelsOrder,
    getNewAccessibleLanguages,
    getHiddenLanguages,
    getDefaultAccessibleLanguages,
} from '../../helpers/pages/user';
import pickDirtyFields from '../../helpers/form/pickDirtyFields';
import applications from '../../configs/applications';
import {Team} from '../../interfaces/users';
import * as styles from './addUser.module.css';
import {accountVar} from '../../helpers/graphql/variables';
import isDirtyForm from '../../helpers/form/isDirtyForm';
import {EditUserDocument} from '../../graphql-types';
import toastify from '../../helpers/toast/toastify';

const levels = {
    global: 1,
    region: 2,
    bu: 3,
};

interface IEditUserModal {
    setModal: (value: string | false) => void,
    rowDataId: string,
    userData: any,
    teamsData: any,
    isBu: boolean,
}

const EditUser = ({
    setModal,
    rowDataId,
    userData,
    teamsData,
    isBu,
}: IEditUserModal) => {
    const [editUser, {loading}] = useMutation(EditUserDocument);
    const teamsOptionsData = isBu ? teamsData : teamLevelsOrder(teamsData);
    const teamOptions = teamsOptionsData.map((item: Team) => ({
        id: item.id,
        value: item.id,
        label: item.name,
        level: levels[item.level],
        type: item.level,
    }));
    const [defaultTeam] = teamOptions.filter((item: {value: string}) => item.value === userData.team.id);
    const {
        setValue,
        control,
        handleSubmit,
        reset,
        formState: {
            isValid,
            dirtyFields,
        },
    } = useForm<FieldValues>({
        mode: 'onChange',
        defaultValues: {},
    });
    const isDirty = isDirtyForm(dirtyFields);
    const [selectedTeam, selectedLanguages] = useWatch({
        control,
        name: ['teamId', 'hiddenLanguageIds'],
    });
    const isMyUser = accountVar()?.userId === rowDataId;

    const onSubmit = (formData: FieldValues) => {
        const newData = {
            name: formData.name,
            email: formData.email,
            teamId: formData.teamId,
            isTeamOwner: formData.isTeamOwner,
            hiddenLanguageIds: getHiddenLanguages(formData.hiddenLanguageIds),
            permissions: formData.permissions,
        };

        editUser({
            variables: {
                id: rowDataId,
                input: pickDirtyFields(newData, dirtyFields),
            },
            onCompleted(data) {
                if (data) {
                    navigate('/users', {
                        state: {
                            toast: {
                                type: 'success',
                                text: 'User updated',
                            },
                        },
                    });
                    setModal(false);

                    return;
                }

                toastify({type: 'error', text: 'There was an error on update'});
            },
            onError(err) {
                toastify({type: 'error', text: `There was an error on update: ${err.message}`});
            },
            refetchQueries: ['getTeams'],
            onQueryUpdated(observableQuery) {
                return observableQuery.refetch();
            },
        });
    };

    useEffect(() => {
        reset({
            name: userData.name,
            email: userData.email,
            teamId: defaultTeam.id,
            isTeamOwner: userData.isTeamOwner,
            hiddenLanguageIds: getDefaultAccessibleLanguages(teamsData, defaultTeam.id, userData.hiddenLanguages),
            permissions: userData.permissions,
        });
    }, [userData]);

    useEffect(() => {
        if (!selectedTeam || !isDirty) {
            return;
        }

        setValue('hiddenLanguageIds', getNewAccessibleLanguages(teamsData, selectedTeam, selectedLanguages));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        selectedTeam,
        setValue,
        teamsData,
    ]);

    return (
        <Modal
            title="Edit user"
            closeModal={() => setModal(false)}
            buttons={(
                <Button
                    text="Confirm and change"
                    disabled={!isValid || loading}
                    size="medium"
                    onClick={handleSubmit(onSubmit)}
                    loading={loading}
                />
            )}
        >
            <Form>
                <Form.Row>
                    <Form.Column>
                        <Input
                            control={control}
                            rules={{
                                required: {
                                    value: true,
                                    message: 'Name is required',
                                },
                            }}
                            title="Name"
                            name="name"
                            placeholder="write the whole name"
                        />
                    </Form.Column>
                    <Form.Column>
                        <Input
                            control={control}
                            title="Email"
                            name="email"
                            disabled={true}
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column>
                        <SelectBox
                            optionsValues={teamOptions}
                            control={control}
                            required={true}
                            title="Team"
                            placeholder="choose the team"
                            controllerName="teamId"
                            isDisabled={isBu || userData.isTeamOwner}
                        />
                    </Form.Column>
                    <Form.Column alignVertical="center" className={styles.ownerCheckbox}>
                        <Controller
                            control={control}
                            name="isTeamOwner"
                            render={({
                                field: {
                                    onChange,
                                    value,
                                },
                            }) => (
                                <Checkbox
                                    checked={value}
                                    label="Owner"
                                    name="isTeamOwner"
                                    handleChange={() => {
                                        onChange(value !== true);
                                    }}
                                    disabled={isMyUser}
                                />
                            )}
                        />
                    </Form.Column>
                </Form.Row>
                <div className={styles.scopesLabel}>
                    <span>Permissions</span>
                </div>
                <Form.Row>
                    <Form.Column>
                        {Object
                            .keys(applications)
                            .filter(appKey => applications[appKey].type === 'item')
                            .map(app => {
                                const {name, id} = applications[app];

                                return (
                                    <Scopes
                                        key={`ApplicationScopes_${id}`}
                                        name={name}
                                        group="permissions"
                                        id={id || 'generic'}
                                        control={control}
                                    />
                                );
                            })
                        }
                        <Scopes
                            name="Analytics"
                            group="permissions"
                            id="analytics"
                            standaloneScopes={[
                                'we',
                                'reemea',
                                'apac',
                            ]}
                            control={control}
                        />
                    </Form.Column>
                </Form.Row>
                <Form.Row>
                    <Form.Column>
                        <CheckboxGroup
                            title="Accessible languages"
                            group="hiddenLanguageIds"
                            control={control}
                        />
                    </Form.Column>
                </Form.Row>
            </Form>
        </Modal>
    );
};

export default EditUser;
